<!-- <div *ngIf="!editable && !grnCreateBoolean" class="pos_rel">
  <span class="goBackIn ml-3" (click)="backToInvoice()">
    <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
  </span>
  <h6 class="headh6 pl-80">View {{ Itype }}</h6>
</div>
<div *ngIf="editable || grnCreateBoolean" class="pos_rel">
  <span class="goBackIn ml-3" (click)="backToInvoice()">
    <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
  </span>
  <h6 class="headh6 pl-80">{{ headerName }}</h6>
</div> -->
<div class="row invoice-body">
  <div class="viewPdf d-flex">
    <!-- <button
      class="btnUpload  mr-2"
      (click)="open_dialog_comp('Amend')"
    >
      PO Amend request
    </button> -->

    <!-- [ngClass]="subStatusId == 75 || subStatusId == 17 ? 'bt-hlt' : ''" -->

    <div
      class="d-flex"
      *ngIf="
        (editPermissionBoolean ||
          changeApproveBoolean ||
          financeApproveBoolean) &&
        submitBtn_boolean &&
        isDesktop && !isServiceData &&
        currentTab == 'line'  || router.url.includes('Inv_vs_GRN_details')
      "
    >
      
      <button
        *ngIf="
          portalName == 'customer' && !isServiceData && !fin_boolean && editable && !['advance invoice','non-po','credit note'].includes(documentType) || mappingForCredit || (router.url.includes('Inv_vs_GRN_details') && !GRN_PO_Bool)
        "
        class="secondary_btn f-12 m-t-neg ml-2 mr-2"
        (click)="opengrnDailog()"
      >
        Select GRN
      </button>
      <button
        *ngIf="
          portalName == 'customer' && 
          ((this.router.url.includes('Upload') && (client_name != 'Emaar Hospitality')) || 
          [8,16,17,18,19,21,27,34,205].includes(subStatusId) || 
          (documentType == 'advance invoice' && 
          [29,108].includes(subStatusId) && 
          this.userDetails['permissioninfo'].is_fp))
        "
        class="secondary_btn f-12 mr-2"
        (click)="open_dialog_comp('flip line')"
      >
        Flip PO
      </button>

      <button
        *ngIf="(subStatusId == 32 || subStatusId == 40) && isAdmin"
        class="level_3_btn f-12 mr-2"
        (click)="status_dialog()"
      >
        Change status
      </button>
    </div>

    <div *ngIf="Itype == 'Invoice' && statusId != 10 && editable && !fin_boolean && !headerName.includes('Create')" class="mr-2 invType">
      <p-autoComplete
        [(ngModel)]="entityName"
        (onSelect)="onSelectEntity($event, 'change')"
        placeholder="Select Entity"
        [suggestions]="filteredEnt"
        (completeMethod)="filterEntity($event)"
        field="EntityName"
        [dropdown]="true"
        name="entity"
        styleClass="mr-3"
      >
        <ng-template let-ent pTemplate="item">
          <div class="ent-item">
            <div class="f-12">{{ ent.EntityName }}</div>
          </div>
        </ng-template>
      </p-autoComplete>

      <p-dropdown
        (onChange)="onSelectInvType($event)"
        [options]="invTypeList"
        placeholder="Select invoice type"
        name="invType"
        [(ngModel)]="docType"
      >
      </p-dropdown>
    </div>
    <div class="f-12 mt-2 mr-2" *ngIf="!editable || fin_boolean">
       <span *ngIf="ent_code || d_type" class="status_btn" style="text-transform: capitalize;"><span *ngIf="ent_code">Entity Code - {{ent_code}} </span> <span *ngIf="d_type"><span *ngIf="ent_code">&</span> Invoice type - {{d_type}}</span></span>
    </div>
    <button
      *ngIf="!isPdfAvailable && isDesktop && currentTab != 'header'"
      class="primary_btn mr-2 f-12"
      (click)="viewPdf()"
    >
      {{ btnText }}
    </button>
  </div>
  <!-- <div
    class="viewPdf"
    style="right: 100px"
    *ngIf="!isPdfAvailable && isDesktop"
  >
    <button class="btnUpload" (click)="DownloadPDF()">
      <i class="fa fa-download" aria-hidden="true"></i>
    </button>
  </div> -->

  <!-- <div *ngIf="submitBtn_boolean || approveBtn_boolean && isDesktop" class="position_rule">
    <div class="placement-ruleChange f-12 bg-div-rule">
      Current Selected rule : {{ selectedRule }}
    </div>
    <button
      class="placement-ruleChange btnUpload bg-grn-btn"
      (click)="open_modal()"
    >
      Change
    </button>
  </div> -->
  <!-- <div *ngIf="approveBtn_boolean">
    <div
      class="placement-ruleChange f-12 bg-div-rule"
      style="left: inherit; right: 145px; position: absolute"
    >
      Current Selected Approval Type : {{ approvalType }}
    </div>
  </div> -->

  <!-- <div *ngIf="submitBtn_boolean && isDesktop && !GRN_PO_Bool && router.url.includes('comparision-docs')" class="changePOInput">
    <p-autoComplete
      [(ngModel)]="selectedPONumber"
      (onSelect)="onSelectPO($event)"
      placeholder="Select PO Number"
      [suggestions]="filteredPO"
      (completeMethod)="filterPO($event)"
      field="PODocumentID"
      [dropdown]="true"
    >
      <ng-template let-country pTemplate="item">
        <div class="country-item">
          <div class="f-12">{{ country.PODocumentID }}</div>
        </div>
      </ng-template>
    </p-autoComplete>
  </div> -->
  <div
    class="col-md-12 p-0"
    [ngClass]="showPdf && !isPdfAvailable ? 'col-lg-5' : 'col-lg-12'"
  >
    <div class="col-12 dummy_div"></div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <!-- <li
        class="nav-item mr-2"
        pTooltip="Vendor details"
        tooltipPosition="top"
        (click)="changeTab('vendor')"
      >
        <a
          class="nav-link"
          id="vendor-tab"
          data-toggle="tab"
          href="#vendor"
          role="tab"
          aria-controls="vendor"
          aria-selected="true"
          >Vendor</a
        >
      </li> -->
      <li
        class="nav-item mr-2"
        pTooltip="Header details"
        tooltipPosition="top"
        (click)="changeTab('header')"
      >
        <a
          class="nav-link"
          [ngClass]="currentTab == 'header' ? 'active' : ''"
          id="header-tab"
          data-toggle="tab"
          href="#header"
          role="tab"
          aria-controls="header"
          aria-selected="false"
          ><span class="fa fa-file-o f-16 font_weight100"></span
          >{{ Itype }} Details</a
        >
      </li>
      <li
        class="nav-item"
        pTooltip="Line details"
        tooltipPosition="top"
        (click)="changeTab('line')"
      >
        <a
          class="nav-link"
          id="line-tab"
          data-toggle="tab"
          href="#line"
          role="tab"
          aria-controls="line"
          aria-selected="false"
          [ngClass]="currentTab == 'line' ? 'active' : ''"
        >
          <span class="fa fa-columns f-16 font_weight100"></span>Line Details</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="PO Lines details"
        tooltipPosition="top"
        (click)="changeTab('poline')"
        *ngIf="Itype == 'Invoice' && (['Emaar Hospitality'].includes(client_name) && documentType?.toLowerCase() == 'credit note')"
      >
        <a
          class="nav-link"
          id="POline-tab"
          data-toggle="tab"
          href="#POline"
          role="tab"
          aria-controls="POline"
          aria-selected="false"
          [ngClass]="currentTab == 'poline' ? 'active' : ''"
          >PO Lines</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="GRN details"
        tooltipPosition="top"
        *ngIf="!grnCreateBoolean && Itype == 'Invoice' && !(['Emaar Hospitality'].includes(client_name) && documentType?.toLowerCase() == 'credit note')"
        (click)="changeTab('grn')"
      >
        <a
          class="nav-link"
          id="grn-tab"
          data-toggle="tab"
          href="#grn"
          role="tab"
          aria-controls="grn"
          aria-selected="false"
          [ngClass]="currentTab == 'grn' ? 'active' : ''"
          ><span class="fa fa-columns f-16 font_weight100"></span>GRN Data</a
        >
      </li>
      <li
        class="nav-item ml-2"
        pTooltip="Supporting documents"
        tooltipPosition="top"
        *ngIf="['Invoice','GRN'].includes(Itype) && !approval_selection_boolean || tagService?.headerName?.includes('GRN')"
        (click)="changeTab('support')"
      >
        <a
          class="nav-link"
          id="support-tab"
          data-toggle="tab"
          href="#support"
          role="tab"
          aria-controls="support"
          aria-selected="false"
          [ngClass]="currentTab == 'support' ? 'active' : ''"
          ><span class="fa fa-columns f-16 font_weight100"></span>Supporting
          Docs</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="history"
        tooltipPosition="top"
        *ngIf="approvalRejectRecord.length > 0 && fin_boolean"
        (click)="changeTab('history')"
      >
        <a
          class="nav-link"
          id="history-tab"
          data-toggle="tab"
          href="#history"
          role="tab"
          aria-controls="history"
          aria-selected="false"
          [ngClass]="currentTab == 'history' ? 'active' : ''"
          ><span class="fa fa-columns f-16 font_weight100"></span>History
        </a>
      </li>
      <li
        *ngIf="approval_selection_boolean && isLCMCompleted"
        class="nav-item ml-2"
        pTooltip="Approver Selection"
        tooltipPosition="top"
        (click)="changeTab('approver_selection')"
      >
        <a
          class="nav-link"
          id="approver_selection-tab"
          data-toggle="tab"
          href="#approver_selection"
          role="tab"
          aria-controls="approver_selection"
          aria-selected="false"
          [ngClass]="
            approval_selection_boolean &&
            !isLCMInvoice &&
            currentTab == 'approver_selection'
              ? 'active'
              : ''
          "
          >Approver Selection</a
        >
      </li>
      <li
        class="nav-item ml-2"
        pTooltip="Fixed Cost allocation"
        tooltipPosition="top"
        (click)="changeTab('cost')"
        *ngIf="isServiceData && client_name != 'Enova'"
      >
        <a
          class="nav-link"
          id="cost-tab"
          data-toggle="tab"
          href="#cost"
          role="tab"
          aria-controls="cost"
          aria-selected="true"
          [ngClass]="currentTab == 'cost' ? 'active' : ''"
          >Fixed Allocation</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="Dynamic cost allocation"
        tooltipPosition="top"
        (click)="changeTab('dynamic')"
        *ngIf="isServiceData && dynamicdata.length>0"
      >
        <a
          class="nav-link"
          id="dynamic-tab"
          data-toggle="tab"
          href="#dynamic"
          role="tab"
          aria-controls="dynamic"
          aria-selected="true"
          [ngClass]="currentTab == 'dynamic' ? 'active' : ''"
          >Dynamic Allocation</a
        >
      </li>
      <!-- <button class="raiseAlert" pTooltip="raise an alert" tooltipPosition="top"><i class="fa fa-exclamation-triangle"
            aria-hidden="true"></i></button> -->
    </ul>
    <div class="tabsDiv bg-design-all">
      <div *ngIf="!isDesktop" class="vi_mb_btn f-12" (click)="doc_view()">
        <i class="fa fa-eye"></i> View Document
      </div>
      <form
        #form="ngForm"
        class="tabDiv"
        [ngClass]="currentTab == 'line' ? 'line_height' : ''"
        id="myTabContent"
      >
        <div class="tab-content">
          <ng-container *ngTemplateOutlet="header"></ng-container>
          <ng-container *ngTemplateOutlet="line"></ng-container>
          <ng-container *ngTemplateOutlet="cost"></ng-container>
          <ng-container *ngTemplateOutlet="dynamic"></ng-container>
          <ng-container *ngTemplateOutlet="poline"></ng-container>
          <ng-container *ngTemplateOutlet="grn"></ng-container>
          <ng-container *ngTemplateOutlet="support"></ng-container>
          <ng-container *ngTemplateOutlet="history"></ng-container>
          <ng-container *ngTemplateOutlet="approver_selection"></ng-container>
        </div>

        <!-- header -->
        <ng-template #header>
          <div
            class="tab-pane fade"
            [ngClass]="currentTab == 'header' ? 'show active' : ''"
            id="header"
            role="tabpanel"
            aria-labelledby="header-tab"
          >
          <!-- <span class="boxopen" *ngIf="documentType == 'advance invoice'" >
            <div class="button-container">
              <button class="advSettingsButton" (click)="openBox()"> Pre Payment </button>
            </div>
          </span> -->
            <div
              class="header_container"
              [ngClass]="!editable ? 'disable_div' : ''"
            >
              <div class="d-flex justify-content-between">
                <div class="f-14">{{ Itype }}/Purchase Details</div>
                <div class="f-12 status_btn" *ngIf="doc_status" [title]="doc_status" style="background-color: inherit;">
                  {{ doc_status }}
                </div>
              </div>
              <div class="displayFlex mt-2">
                <div *ngFor="let value of inputData">
                  <!-- [ngStyle]="{ 'width':value?.TagLabel?.includes('Vendor') ? 'calc(100svw - 74svw)':''}" -->
                  <div
                    *ngIf="!value?.TagLabel?.toLowerCase().includes('dummy')"
                    class="input_container"
                    [ngClass]="
                      value?.TagLabel?.includes('Vendor')
                        ? 'large_input_box'
                        : ''
                    "
                    [ngStyle]="{
                      border:
                        value.isError >= 1 && !value.ErrorDesc.includes('Low')
                          ? '1px solid #D81616'
                          :subStatusId == 110 && value?.TagLabel == 'Current Month VAT'
                          ? '1px solid #D81616'
                          :value?.TagLabel == 'Customer TRN Number' && value.Value == ''
                          ? '1px solid #D81616'
                          :value.isError >= 1 && value.ErrorDesc.includes('Low')
                          ? '1px solid #f7c83e'
                          : value.isError === 0 && value.IsUpdated === 0
                          ? '0px solid lightgray'
                          : value.IsUpdated > 0
                          ? '1px solid #37dcc7'
                          : ''
                    }"
                  >
                    <label class="label-head" style="margin-bottom: -5px"
                      >{{ value.TagLabel }} <span *ngIf="value?.TagLabel?.toLowerCase() == 'invoicedate'">(YYYY-MM-DD)</span>
                      <span
                        *ngIf="value.isError == 1"
                        [pTooltip]="value.ErrorDesc"
                        tooltipPosition="top"
                        ><i
                          class="fa fa-info-circle info_icon"
                          aria-hidden="true"
                        ></i
                      ></span>
                      <!-- <span
                        *ngIf="value.TagLabel.toLowerCase() == 'invoicedate'"
                        pTooltip="Date format: "
                        tooltipPosition="top"
                        ><i
                          class="fa fa-info-circle info_icon"
                          aria-hidden="true"
                        ></i
                      ></span> -->
                      <span
                        *ngIf="value.TagLabel == 'PurchaseOrder' && editable"
                      >
                        <i
                          class="fa fa-search info_icon pointer po_s_icon"
                          aria-hidden="true"
                          (click)="poDailog(value)"
                        ></i>
                      </span>
                    </label>
                    <input
                      *ngIf="!value?.TagLabel?.includes('Project')"
                      #inputv
                      [type]="value?.TagLabel?.includes('AmountToApply')?'number':'text'"
                      [value]="value.Value"
                      [title]="value.Value"
                      [disabled]="!editable || fin_boolean || value?.TagLabel?.includes('Cost Account')"
                      (change)="
                      value?.TagLabel?.includes('AmountToApply')?progressiveAmount(inputv.value,value.idDocumentData):onChangeValue(value.TagLabel, inputv.value, value)
                      "
                      (blur)="saveChanges()"
                      class="form-control inv_inp_cls"
                    />
                    <div *ngIf="value?.TagLabel?.includes('Project')" class="project">
                      <p-autoComplete
                        [(ngModel)]="value.Value"
                        (onSelect)="onSelectProject($event, value)"
                        placeholder=""
                        [suggestions]="filteredProject"
                        (completeMethod)="filterProject($event,value.TagLabel,value.Value)"
                        field=""
                        [dropdown]="true"
                        [name]="value.TagLabel"
                        styleClass="project"
                        [disabled]="!editable || fin_boolean"
                      >
                      </p-autoComplete>
                    </div>
                    <span *ngIf="value?.DocumentUpdates?.OldValue">
                      <span class="old_value"
                        >Prev :
                        <span
                          style="color: #f38a6b"
                          [title]="value?.DocumentUpdates?.OldValue"
                          >"{{ value?.DocumentUpdates?.OldValue }}"</span
                        >
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- vendor or service details -->
            <div class="header_container disable_div mt-2">
              <div class="d-flex justify-content-between">
                <div class="f-14">
                  {{ isServiceData ? "Service" : "Vendor" }} Details as per ERP
                </div>
                <div class="f-12">View Only</div>
              </div>
              <div class="displayFlex">
                <div *ngFor="let data of vendorData | keyvalue; let i = index">
                  <div
                    class="input_container"
                    [ngClass]="
                      data?.key?.includes('VendorName') ? 'large_input_box' : ''
                    "
                    *ngIf="!data?.key.startsWith('id')"
                  >
                    <label class="label-head" style="margin-bottom: -5px"
                      >{{ data.key }}
                    </label>
                    <input
                      type="text"
                      [value]="data.value"
                      [title]="data.value"
                      class="form-control inv_inp_cls"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isBoxOpen" class="amount-box">

              <h6 class="headamtbox">Please Select</h6>
              <div class="close-icon" (click)="closeBox()"><i class="fa fa-times" aria-hidden="true"></i>
              </div>

                <ul class="tabs">
                  <span class="percentage" (click)="activeTab = 'percentage'" [class.active]="activeTab === 'percentage'">Percentage</span>
                  <span class="amount"(click)="activeTab = 'amount'" [class.active]="activeTab === 'amount'">Amount</span>
                </ul>
              <div class="tabContent">
                <div *ngIf="activeTab === 'percentage'" class="tabBg">
                  <label for="percentageInput" class="boxHead">Add Percentage:</label>
                  <input type="text" name="percentageInput" id="percentageInput" [(ngModel)]="percentageData"  (ngModelChange)="updateButtonState('percentage')" pattern="[0-9]*">
                  <label>Amount: {{ resultAmount }}</label>
                  <!-- <input type="text" [value]="resultAmount" readonly> -->
                  <!-- <button (click)="savePercentage()">Submit Percentage</button> -->
                  <div class="subButton">
                    <button class="submitButton"
                    (click)="saveData('percentage')" [disabled]="isButtonDisabled"
                    >Submit</button>
                  </div>
                </div>
              
                <div *ngIf="activeTab === 'amount'" class="tabBg">
                  <label for="amountInput" class="boxHead">Add Amount:</label>
                  <input type="text" id="amountInput" name="amountInput" [(ngModel)]="amountData" (input)="updateButtonState('amount')" pattern="[0-9]*">
              
                  <!-- <button (click)="saveAmount()">Submit Amount</button> -->
                  <div class="subButton">
                    <button class="submitButton"
                    (click)="saveData('amount')" [disabled]="isButtonDisabled"
                    >Submit</button>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </ng-template>

        <!-- line and GRN creations -->
        <ng-template #line>
          <!-- line -->
          <div
            class="tab-pane fade"
            [ngClass]="currentTab == 'line' ? 'show active' : ''"
            id="line"
            role="tabpanel"
            aria-labelledby="line-tab"
          >

            <div
              *ngIf="
                !isDesktop &&
                editable &&
                router.url.includes('comparision-docs') &&
                pageType == 'mapping'
              "
              class="d-flex mb-1"
              style="gap: 5px"
            >
              <button
                *ngIf="
                  portalName == 'customer'
                  && client_name == 'Enova' && !isServiceData && !fin_boolean && editable
                  && !['advance invoice'].includes(documentType)
                "
                class="level_3_btn f-12 m-t-neg ml-2 mr-2"
                (click)="opengrnDailog()"
              >
                Select GRN
              </button>
              <!-- <button
                *ngIf="
                  portalName == 'customer' &&
                  flipEnabled && [8,16,17,18,19,21,27,34,205].includes(subStatusId) || (documentType == 'advance invoice' && [29,108].includes(subStatusId) && this.userDetails['permissioninfo'].is_fp)
                "
                class="secondary_btn f_12 m-t-neg ml-2"
                (click)="open_dialog_comp('flip line', '')"
              >
                Flip PO
              </button> -->
            </div>
            <div *ngIf="!grnCreateBoolean && pageType == 'mapping'">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex">
                  <div class="f-14 mr-3">
                    Invoice Line/Purchase Line Details
                  </div>
                  <div class="f-12 status_btn" *ngIf="doc_status" style="background-color: inherit;">
                    {{ doc_status }}
                  </div>
                </div>

                <div class="d-flex f-12 basic_details text_color" *ngIf="!isServiceData">
                  <div class="mr-2">{{ vendorName }}</div>
                  <div class="pipe mr-2">|</div>
                  <div class="mr-2">{{ po_num }}</div>
                  <div class="pipe mr-2">|</div>
                  <div>{{ invoiceNumber }}</div>
                </div>

                <div
                  class="d-flex"
                  [ngClass]="
                    isDesktop ? 'justify-content-end' : 'justify-content-start'
                  "
                >
                  <!-- <div class="d-flex mr-3">
                    <div class="represent_color invoice_color"></div>
                    <div class="f-12">Invoice</div>
                  </div> -->
                  <button
                    *ngIf="editable && !fin_boolean"
                    id="addLine"
                    class="level_3_btn f-12 mr-4"
                    (click)="open_dialog('AddLine')"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i> Add Line
                  </button>
                  <!-- <div class="d-flex mr-3">
                    <div
                      class="represent_color"
                      style="background-color: red"
                    ></div>
                    <div class="f-12">Mis-matched</div>
                  </div> -->
                </div>
              </div>

              <table class="w-100 mt-2">
                <tr>
                  <td class="count_line">
                    <span class="f-13 p-2" style="margin-top: -6px">S.No</span>
                    <div
                      class="f-12 mb-1 "
                      [ngClass]="(documentType == 'advance invoice' || documentType == 'non-po')? 'count-h_normal':'count-h' "

                      *ngFor="let tagName of lineCount; let i = index"
                    >
                      {{ i + 1 }}
                      <!-- <div class="f-12 td_padding invoice_color doc_height">
                        {{ i + 1 }}
                      </div>
                      <div class="f-12 td_padding po_color doc_height"></div> -->
                    </div>
                  </td>
                  <td
                    class="f-13"
                    *ngFor="let tagName of lineDisplayData; let i = index"
                    [ngStyle]="{
                      width:
                        tagName?.tagname == 'Description' ? '100%' : '200px',
                      textAlign:
                        tagName.tagname == 'Description' ? 'left' : 'center'
                    }"
                  >
                    <span class="f-13 p-2">{{ tagName.tagname }}</span>
                    <ng-container
                      *ngFor="let data of tagName.items; let l_i = index"
                    >
                      <ng-container
                        *ngFor="let data2 of data.linedetails; let x = index"
                        class="mb-1"
                      >
                        <div
                          class="f-12 td_padding invoice_color doc_height"
                          [ngStyle]="{ borderBottom:(documentType == 'advance invoice' || documentType == 'non-po')? '1px solid lightgray':'' }"
                          data-text="IN"
                          [ngClass]="
                            tagName.tagname == 'Description'
                              ? 'textType in_bg_clr'
                              : ''
                          "
                        >
                          <!-- <div
                              class="error-block"
                              [ngClass]="
                                data2.invline[0]?.DocumentLineItems.isError >= 1
                                  ? 'error-symbol'
                                  : ''
                              "
                              [pTooltip]="
                                data2.invline[0]?.DocumentLineItems?.ErrorDesc
                              "
                              tooltipPosition="top"
                            ></div> -->
                          <input
                            *ngIf="!['PurchaseOrder','InvoiceNumber'].includes(tagName.tagname)"
                            type="text"
                            #lineInput
                            [title]="data2.invline[0]?.DocumentLineItems.Value"
                            [value]="data2.invline[0]?.DocumentLineItems.Value"
                            class="f-12 input_disabled w-fill"
                            style="text-transform: lowercase"
                            [disabled]="!editable || fin_boolean"
                            (change)="
                              onChangeLineValue(
                                tagName.tagname,
                                lineInput.value,
                                data2.invline[0]?.DocumentLineItems
                              )
                            "
                            (blur)="saveChanges()"
                            [ngClass]="!editable ? 'input_disabled' : ''"
                            [ngStyle]="{
                              width:
                                tagName.tagname == 'Description'
                                  ? '-webkit-fill-available'
                                  : '150px',
                              textAlign:
                                tagName.tagname == 'Description'
                                  ? 'left'
                                  : 'center',
                              borderBottom:
                                data2.invline[0]?.DocumentLineItems.isError >= 1 && !data2?.invline[0]?.DocumentLineItems?.ErrorDesc?.includes('Low')
                                  ? ' 2px solid red'
                                  : data2.invline[0]?.DocumentLineItems.isError >= 1 && data2?.invline[0]?.DocumentLineItems?.ErrorDesc?.includes('Low')
                                  ? ' 2px solid #f7c83e'
                                  : data2.invline[0]?.DocumentLineItems.IsUpdated > 0 && data2.invline[0]?.DocumentLineItems.isError == 0
                                  ? '2px solid #37dcc7'
                                  : ''
                            }"
                          />
                          <div *ngIf="['PurchaseOrder','InvoiceNumber'].includes(tagName.tagname)">
                            <p-autoComplete
                              [(ngModel)]="data2.invline[0]?.DocumentLineItems.Value"
                              (onSelect)="onSelectPrePay($event, data2.invline[0]?.DocumentLineItems,tagName.tagname,x)"
                              placeholder=""
                              [suggestions]="filteredPreData"
                              (completeMethod)="filterPreDrop($event,tagName.tagname)"
                              field=""
                              [dropdown]="true"
                              styleClass="f-12"
                              [name]="tagName.tagname"
                              [disabled]="!editable || fin_boolean"
                            >
                            </p-autoComplete>
                          </div>
                          <span
                            *ngIf="data2?.invline[0]?.DocumentUpdates"
                            class="prev_value"
                            [title]="
                              data2?.invline[0]?.DocumentUpdates?.OldValue
                            "
                            >prev:
                            {{
                              data2.invline[0]?.DocumentUpdates.OldValue
                            }}</span
                          >
                          <span
                            *ngIf="
                              data2?.invline[0]?.DocumentLineItems?.isError >= 1 
                            "
                            [pTooltip]="
                              data2?.invline[0]?.DocumentLineItems?.ErrorDesc
                            "
                            tooltipPosition="top"
                            ><i
                              [ngStyle]="{
                                color:
                                  data2?.invline[0]?.DocumentLineItems?.isError >= 1 && !data2?.invline[0]?.DocumentLineItems?.ErrorDesc.includes('Low')
                                    ? '#D81616'
                                    :data2?.invline[0]?.DocumentLineItems?.isError >= 1 && data2?.invline[0]?.DocumentLineItems?.ErrorDesc.includes('Low')
                                    ? '#f7c83e'
                                    : ''
                              }"
                              class="fa fa-info-circle info_icon"
                              aria-hidden="true"
                            ></i
                          ></span>
                        </div>
                        <div
                          *ngIf="documentType != 'advance invoice' && documentType != 'non-po'"
                          class="f-12 td_padding po_color doc_height"
                          data-text="PO"
                          [ngClass]="
                            tagName.tagname == 'Description'
                              ? 'textType po_bg_clr'
                              : ''
                          "
                        >
                          <input
                            *ngIf="
                              (tagName.tagname != 'Description' ||
                                !submitBtn_boolean || fin_boolean)
                            "
                            type="text"
                            [title]="data2.poline[0]?.Value"
                            [value]="data2.poline[0]?.Value"
                            class="f-12 input_disabled w-fill"
                            style="text-transform: lowercase"
                            disabled
                            [ngClass]="!editable ? 'input_disabled' : ''"
                            [ngStyle]="{
                              width:
                                tagName.tagname == 'Description'
                                  ? '-webkit-fill-available'
                                  : '150px',
                              textAlign:
                                tagName.tagname == 'Description'
                                  ? 'left'
                                  : 'center'
                            }"
                          />
                          <!-- <input
                            *ngIf="tagName.tagname == 'AmountExcTax'"
                            type="text"
                            [title]="data2.poline[0]?.Value"
                            [value]="
                              (
                                lineDisplayData[1]?.items[l_i]?.linedetails[0]
                                  ?.poline[0]?.Value *
                                lineDisplayData[2]?.items[l_i]?.linedetails[0]
                                  ?.poline[0]?.Value
                              ).toFixed(2)
                            "
                            class="f-12 input_disabled w-fill"
                            style="text-transform: lowercase"
                            disabled
                            [ngClass]="!editable ? 'input_disabled' : ''"
                            [ngStyle]="{
                              width:
                                tagName.tagname == 'Description'
                                  ? '-webkit-fill-available'
                                  : '150px',
                              textAlign:
                                tagName.tagname == 'Description'
                                  ? 'left'
                                  : 'center'
                            }"
                          /> -->
                          <div
                            class="mapping"
                            *ngIf="
                              tagName.tagname == 'Description' &&
                              submitBtn_boolean && !fin_boolean
                            "
                            [title]="data2?.poline[0]?.Value"
                          >
                            <p-autoComplete
                              *ngIf="tagName.tagname == 'Description'"
                              (onSelect)="
                                lineMapping(
                                  data.linedetails[0].invline[0]
                                    ?.DocumentLineItems?.itemCode,
                                  $event.itemCode,
                                  data.itemcode
                                )
                              "
                              [(ngModel)]="data2?.poline[0]"
                              [suggestions]="filteredPOLine"
                              (completeMethod)="filterPOLine($event)"
                              field="Value"
                              [id]="data2?.poline[0]?.idDocumentLineItems"
                              [name]="data2?.poline[0]?.Value"
                              styleClass="f-11 input_disabled sel_drp"
                              [dropdown]="true"
                              appendTo="body"
                            >
                              <ng-template let-line pTemplate="item">
                                <div class="line-item">
                                  <div class="f-11">{{ line.Value }}</div>
                                </div>
                              </ng-template>
                            </p-autoComplete>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </td>

                  <td *ngIf="editable && !fin_boolean">
                    <span class="f-13 p-2 mapping_line hide">Act</span>
                    <div style="margin-top: -5px">
                      <div *ngFor="let data of lineCount">
                        <div *ngFor="let data2 of data.linedetails" class="mb1">
                          <div class="f-12 td_padding invoice_color doc_height"
                          [ngStyle]="{ borderBottom:(documentType == 'advance invoice' || documentType == 'non-po')? '1px solid lightgray':'' }">
                            <button
                              class="trash_btn"
                              (click)="
                                delete_confirmation(
                                  data.linedetails[0].invline[0]
                                    ?.DocumentLineItems?.itemCode
                                )
                              "
                            >
                              <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button>
                          </div>
                          <div *ngIf="documentType != 'advance invoice' && documentType != 'non-po'" class="f-12 td_padding po_color doc_height">
                            <div
                              class="f-12 d-flex"
                              *ngIf="submitBtn_boolean"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>

              <table class="f-13 total_table">
                <tr class="mb-1">
                  <td></td>
                  <td class="total_txt">Invoice Lines Total </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="total_count" [pTooltip]="lineTooltip" tooltipPosition="top">{{totalInvCost}}</td>
                  <td *ngIf="editable" style="width: 40px"></td>
                </tr>
                <tr *ngIf="documentType != 'advance invoice' && documentType != 'non-po'">
                  <td></td>
                  <td class="total_txt">Purchase Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="total_count">{{ po_total?.toFixed(2) }}</td>
                  <td *ngIf="editable" style="width: 40px"></td>
                </tr>
              </table>
            </div>

            <!-- Create GRN screen Line details -->
            <div *ngIf="grnCreateBoolean">
              <!--GRN Creation With Invoice-->
              <div
                class="d-flex justify-content-between flex-wrap"
                style="height: 30px"
              >
                <div class="d-flex">
                  <div class="f-14 mr-3">
                    {{ headerName }}
                  </div>
                  <div
                    class="f-12 status_btn"
                    style="background-color: inherit;"
                    *ngIf="
                      !router.url.includes('Inv_vs_GRN_details') &&
                      !router.url.includes('GRN_approvals')
                    "
                  >
                    {{ doc_status }}
                  </div>
                </div>

                <div class="d-flex f-12 basic_details text_color" *ngIf="!isServiceData">
                  <div class="mr-2">{{ vendorName }}</div>
                  <div class="pipe mr-2">|</div>
                  <div class="mr-2">{{ po_num }}</div>
                  <div class="pipe mr-2">|</div>
                  <div>
                    <span *ngIf="!GRN_PO_Bool">{{ invoiceNumber }}</span>
                    <input
                      *ngIf="GRN_PO_Bool"
                      type="text"
                      name="inv_number"
                      [ngModelOptions]="{standalone: true}"
                      class="inv_number f-12 text_color"
                      placeholder="Please add invoice number"
                      [(ngModel)]="invoiceNumber"
                    />
                  </div>


                    <div class="ml-5" *ngIf="client_name == 'SRG'">
                      <label class="f-12">Invoice Description:</label>
                      <input
                        type="text"
                        name="inv_description"
                        [ngModelOptions]="{standalone: true}"
                        class="inv_number f-12 text_color"
                        placeholder="Please add invoice description"
                        [(ngModel)]="invoiceDescription"
                      />
                    </div>

                    <div class="ml-5 grn" *ngIf="client_name == 'SRG'">
                      <label class="f-12">GRN Date:</label>
                      <p-calendar
                        #grnDate
                        [(ngModel)]="invoiceDate"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        placeholder=""
                        [readonlyInput]="true"
                        [showIcon]="true"
                        name="grnDate"
                        inputId="GRN_Date"
                      ></p-calendar>
                    </div>
                </div>

                <div *ngIf="client_name != 'SRG'" [ngClass]="GRN_PO_Bool && client_name == 'Cenomi'?'':'hide'">
                    <!-- <div class="f-12" (click)="open_dialog_comp('manpower_metadata')" *ngIf="isManpowerTags">Edit Metadata <i class="fa fa-pencil-square-o" aria-hidden="true"></i> </div> -->
                    <!-- <label class="f-12">Time sheet
                      <input type="checkbox" (change)="onChecked($event.target.checked)">
                    </label> -->
                    <button class="f-12 primary_btn" *ngIf="isManpower" (click)="open_dialog_comp('manpower')">Timesheet</button>
                  </div>
              </div>
              <table class="invert mt-2 w-100" id="grnTable">
                <tr class="invert_tr" style="width: 50px">
                  <th class="lineName invert_th_td text-center" style="min-width: 50px">S.No</th>
                  <td *ngFor="let item of grnLineCount; let i = index" class="count_line">
                    <div class="s-no">{{ i + 1 }}</div>
                  </td>
                </tr>
                <ng-container *ngFor="let item of lineDisplayData; let i = index">
                  <tr class="invert_tr" [ngClass]="{'m-l-neg': item.TagName === 'Actions'}" 
                      *ngIf="(GRN_PO_Bool || !item.TagName.includes('UnitPrice')) && item.TagName !== 'Inv - AmountExcTax'">
                    <td class="lineName invert_th_td" [ngClass]="{'m-l-neg': item.TagName === 'Actions'}">
                      <ng-container *ngIf="item.TagName !== 'GRN - AmountExcTax'; else amountExcTax">
                        <div class="d-flex justify-content-between">
                          <div>{{ item.TagName }}</div>
                        </div>
                      </ng-container>
                      <ng-template #amountExcTax>AmountExcTax</ng-template>
                    </td>
                    <td *ngFor="let line1 of item.linedata" class="linevalue invert_th_td" [ngClass]="{'m-l-neg': item.TagName === 'Actions'}">
                      <ng-container [ngSwitch]="item.TagName">
                        <ng-container *ngSwitchCase="'GRN - Quantity'">
                          <div>
                            <input #inputval type="number" style="border: 0.2px solid #358dc0" 
                                 [name]="line1.idDocumentLineItems" [value]="line1.Value" [(ngModel)]="line1.Value"
                                 (change)="onChangeGrn(line1, inputval.value)" [readonly]="line1.is_timesheets"
                                 class="form-control pl-1 inputHeight w-sm" step="0.01" [title]="line1.Value" >
                          </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'AmountExcTax'" [ngSwitch]="GRN_PO_Bool">
                          <ng-container *ngSwitchCase="true && client_name == 'Cenomi'">
                            <div>
                              <input #inputval type="number" style="border: 0.2px solid #358dc0" 
                              [name]="line1.idDocumentLineItems" [value]="line1.Value"
                              (change)="onChangeGrnAmount(line1, inputval.value)" [readonly]="line1.is_timesheets"
                              class="form-control pl-1 inputHeight w-sm"  [title]="line1.Value" >
                            </div>  
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <div>
                              <input #inputval type="text"
                              [name]="line1.idDocumentLineItems" [value]="line1.Value" 
                              [readonly]="line1.is_timesheets"
                              [disabled]="line1.Value" [ngModelOptions]="{standalone: true}"
                              class="form-control pl-1 inputHeight w-sm" [title]="line1.Value" >
                            </div>  
                          </ng-container>
                         
                        </ng-container>
                        <ng-container *ngSwitchCase="'Comments'" [ngSwitch]="GRN_PO_Bool">
                          <ng-container *ngSwitchCase="false">
                            <div>
                              <input #inputval type="text" style="width: 150px; border: 0.2px solid #358dc0"
                                   [ngClass]="{'w-des': item.TagName === 'Description', 'w-sm': item.TagName !== 'Description'}"
                                   [(ngModel)]="line1.ErrorDesc" [name]="line1.idDocumentLineItems" [ngModelOptions]="{standalone: true}" class="form-control pl-1 inputHeight" [title]="line1.ErrorDesc">
                            </div>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Actions'" [ngSwitch]="GRN_PO_Bool">
                          <ng-container *ngSwitchCase="true">
                            <span class="linevalue invert_thtd">
                              <button class="trash_btn" (click)="deleteGrnLine(line1.idDocumentLineItems)">
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                              </button>
                            </span>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                         <div>
                          <input type="text" [ngClass]="{'w-des': item.TagName === 'Description', 'w-sm': item.TagName !== 'Description'}"
                          [ngStyle]="{'border': line1.is_mapped === 1 ? '2px solid #f2be42' : ''}"
                          [value]="line1.Value ? line1.Value : 'NA'" [title]="line1.Value ? line1.Value : ''"
                          class="form-control inputHeight" 
                          [name]="line1.idDocumentLineItems"
                          (click)="!line1.Value? open_dialog_comp('manpower_metadata'): null"
                          [disabled]="line1.Value" [ngModelOptions]="{standalone: true}">
                         </div>
                        </ng-container>
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
            <table
              class="total"
              style="bottom: 0px;"
              *ngIf="
                router.url.includes('Inv_vs_GRN_details') ||
                router.url.includes('GRN_approvals')
              "
            >
              <tr>
                <td class="w-st hide">Total</td>
                <td class="w-des f-12">Total</td>
                <td class="w-st hide">Total</td>
                <td class="w-st hide" *ngIf="!GRN_PO_Bool">Total</td>
                <td
                  class="w-st f-12"
                  [ngClass]="GRN_PO_Bool ? 'text-center' : ''"
                  [ngStyle]="{ paddingLeft: GRN_PO_Bool ? '40px' : '' }"
                >
                  {{ GRN_line_total.toFixed(2) }}
                </td>
                <td
                  class="w-st hide"
                  [ngStyle]="{ width: GRN_PO_Bool ? '54px' : '200px' }"
                >
                  Total
                </td>
                <td class="w-st hide">Total</td>
              </tr>
            </table>

            <div *ngIf="pageType == 'normal'">
              <div
                *ngIf=" editable && editPermissionBoolean"
                class="d-flex mb-1"
                style="gap: 5px"
              >
                <!-- <button
                  *ngIf="portalName == 'customer' && subStatusId == 34 || (documentType == 'advance invoice' && [29,108].includes(subStatusId) && this.userDetails['permissioninfo'].is_fp)"
                  class="secondary_btn f-12 mr-2"
                  (click)="open_dialog_comp('flip')"
                >
                  Flip PO
                </button> -->
                <button
                  *ngIf="
                    portalName == 'customer' &&
                    (subStatusId == 78 || subStatusId == 77)
                  "
                  class="level_3_btn f-12 mr-2"
                  (click)="open_dialog_comp('editApprover')"
                >
                  <span *ngIf="subStatusId == 78">Set</span
                  ><span *ngIf="subStatusId == 77">Edit</span> Approvers
                </button>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex">
                  <div class="f-14 mr-3">
                    Invoice Line/Purchase Line Details
                  </div>
                  <div class="f-12 status_btn" *ngIf="doc_status" style="background-color: inherit;">
                    {{ doc_status }}
                  </div>
                </div>

                <div class="d-flex f-12 basic_details text_color" *ngIf="!isServiceData">
                  <div class="mr-2">{{ vendorName }}</div>
                  <div class="pipe mr-2">|</div>
                  <div class="mr-2">{{ po_num }}</div>
                  <div class="pipe mr-2">|</div>
                  <div>{{ invoiceNumber }}</div>
                </div>

                <div
                  class="d-flex"
                  [ngClass]="
                    isDesktop ? 'justify-content-end' : 'justify-content-start'
                  "
                >
                  <!-- <div class="d-flex mr-3">
                    <div class="represent_color invoice_color"></div>
                    <div class="f-12">Invoice</div>
                  </div> -->
                  <button
                    *ngIf="editable && !fin_boolean"
                    id="addLine"
                    class="level_3_btn f-12 mr-4"
                    (click)="open_dialog('AddLine')"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i> Add Line
                  </button>
                  <!-- <div class="d-flex mr-3">
                    <div
                      class="represent_color"
                      style="background-color: red"
                    ></div>
                    <div class="f-12">Mis-matched</div>
                  </div> -->
                </div>
              </div>

              <table
                *ngIf="isDesktop"
                id="linesdata"
                class="invert"
                style="width: 100%"
              >
                <tr
                  class="invert_tr"
                  *ngFor="let item of lineDisplayData; let i = index"
                  [ngStyle]="{
                    width: item.TagName == 'S.No' ? '50px' : ''
                  }"
                >
                  <th
                    class="lineName invert_th_td"
                    [ngStyle]="{
                      'min-width': item.TagName == 'S.No' ? '40px' : '130px'
                    }"
                  >
                    {{ item.TagName }}
                  </th>
                  <td
                    class="linevalue invert_th_td"
                    *ngFor="let line1 of item.linedata; let i = index"
                  >
                    <div *ngIf="item.TagName == 'S.No'">
                      <div class="linevalue invert_thtd p-2">{{ i + 1 }}</div>
                    </div>
                    <div
                      *ngFor="let line of line1 | keyvalue"
                      class="d-flex"
                      style="position: relative"
                    >
                      <div
                        *ngIf="
                          line.key == 'DocumentLineItems' &&
                          item.TagName != 'S.No'
                        "
                      >
                        <div *ngIf="item.TagName == 'S.No'">
                          <div class="linevalue invert_thtd p-2">
                            {{ line.value.itemCode }}
                          </div>
                        </div>
                        <div *ngIf="item.TagName == 'Actions' && editable && !fin_boolean">
                          <div class="linevalue invert_thtd">
                            <button
                              class="trash_btn"
                              (click)="delete_confirmation(line.value.itemCode)"
                            >
                              <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                        <span
                          *ngIf="
                            item.TagName != 'S.No' && item.TagName != 'Actions'
                          "
                        >
                          <input
                            #inputv
                            type="text"
                            [ngClass]="
                              item.TagName == 'Description' ||
                              item.TagName == 'Name' ||
                              item.TagName == 'POLineDescription' ||
                              item.TagName == 'GRNLineDescription'
                                ? 'w-des'
                                : 'w-sm'
                            "
                            [value]="line.value.Value"
                            [title]="line.value.Value"
                            [disabled]="!editable || fin_boolean"
                            class="form-control f-12 input_disabled"
                            (change)="
                            client_name == 'Enova' && documentType == 'advance invoice' && (item.TagName == 'AdvancePercent' || item.TagName == 'AmountExcTax' )?onChangeAdvance(line.value,inputv.value,item.TagName): onChangeLineValue(
                              item.TagName,
                              inputv.value,
                              line.value
                            )
                            "
                            (blur)="documentType == 'advance invoice' && (item.TagName == 'AdvancePercent' || item.TagName == 'AmountExcTax' )?saveAdChanges():saveChanges()"
                          />
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>

              <table class="f-13 total_table" *ngIf="documentType == 'advance invoice'">
                <tr class="mb-1">
                  <td></td>
                  <td class="total_txt">Invoice Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="total_count">{{ inv_line_total }}</td>
                  <td *ngIf="editable" style="width: 40px"></td>
                </tr>
              </table>
              <div *ngIf="!isDesktop">
                <div class="example-action-buttons f-12">
                  <button mat-button class="f-12" (click)="accordion.openAll()">
                    Expand All
                  </button>
                  <button
                    mat-button
                    class="f-12"
                    (click)="accordion.closeAll()"
                  >
                    Collapse All
                  </button>
                </div>
                <mat-accordion multi>
                  <div *ngFor="let lined of linedata_mobile; let i = index">
                    <mat-expansion-panel
                      [expanded]="true"
                      (opened)="setOpened(i)"
                      (closed)="setClosed(i)"
                      style="margin-top: 10px"
                    >
                      <mat-expansion-panel-header
                        class="KPIName"
                        [ngStyle]="{
                          'background-color': lined.hasError
                            ? '#fff4d2'
                            : lined.hasUpdated
                            ? '#d2e8f9'
                            : ''
                        }"
                      >
                        <mat-panel-title class="secondheadertoggle">
                          <h6 class="f-12 line_mob">
                            Line number - {{ i + 1 }}
                          </h6>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <ng-container>
                        <div class="displayFlex">
                          <div *ngFor="let item of lined">
                            <div *ngIf="item.TagName == 'Actions' && editable && !fin_boolean">
                              <div class="linevalue">
                                <button
                                  class="trash_btn"
                                  (click)="
                                    delete_confirmation(line.value.itemCode)
                                  "
                                >
                                  <i
                                    class="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                            <span
                              *ngIf="
                                item.TagName != 'S.No' &&
                                item.TagName != 'Actions'
                              "
                            >
                              <label
                                class="label-head"
                                style="margin-bottom: -5px"
                              >
                                {{ item.TagName }}</label
                              >
                              <input
                                #inputv
                                type="text"
                                [ngClass]="
                                  item.TagName == 'Description' ||
                                  item.TagName == 'Name' ||
                                  item.TagName == 'POLineDescription' ||
                                  item.TagName == 'GRNLineDescription'
                                    ? 'w-des'
                                    : ''
                                "
                                [(ngModel)]="item.linedata.DocumentLineItems.Value"
                                [title]="item.linedata.DocumentLineItems.Value"
                                [name]="item.TagName"
                                [readonly]="
                                  !editable || fin_boolean || isLinenonEditable
                                "
                                [ngStyle]="{
                                  border:
                                    item.linedata.DocumentLineItems.isError >= 1
                                      ? '2px solid red'
                                      : item.linedata.DocumentLineItems
                                          .isError === 0 &&
                                        item.linedata.DocumentLineItems
                                          .IsUpdated === 0
                                      ? '1px solid lightgray'
                                      : item.linedata.DocumentLineItems
                                          .IsUpdated > 0
                                      ? '2px solid #37dcc7'
                                      : ''
                                }"
                                (change)="
                                  onChangeLineValue(
                                    item.TagName,
                                    inputv.value,
                                    item.linedata.DocumentLineItems
                                  )
                                "
                                (blur)="saveChanges()"
                                class="form-control inputHeight"
                              />
                            </span>
                            <div
                              *ngIf="
                                item.linedata.DocumentLineItems?.isError >= 1 &&
                                item.TagName != 'S.No' &&
                                item.TagName != 'Actions'
                              "
                              [pTooltip]="
                                item.linedata.DocumentLineItems?.ErrorDesc
                              "
                              tooltipPosition="top"
                            >
                              <i
                                class="fa fa-info-circle info_icon"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div
                              *ngIf="
                                item.linedata.DocumentUpdates &&
                                item.TagName != 'S.No' &&
                                item.TagName != 'Actions'
                              "
                              class="old_value"
                            >
                              Prev:
                              <span
                                style="color: #f38a6b"
                                [title]="
                                  item.linedata.DocumentUpdates?.OldValue
                                "
                                >"{{ item.linedata.DocumentUpdates.OldValue }}"
                              </span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </mat-expansion-panel>
                  </div>
                </mat-accordion>
              </div>
            </div>
          </div>
        </ng-template>

        <!-- Cost -->
        <ng-template #cost>
          <div
            class="tab-pane fade header_container"
            [ngClass]="currentTab == 'cost' ? 'show active' : ''"
            id="cost"
            role="tabpanel"
            aria-labelledby="cost-tab"
          >
            <div
              *ngIf="reqServiceprovider"
              class="displayFlex"
              style="display: flex; flex-direction: column"
            >
              <div *ngIf="editable">
                <div
                  *ngFor="let row of rows; let i = index"
                  style="display: flex; flex-direction: row"
                  class="head-box"
                >
                  <div style="position: relative">
                    <label class="label-head">Driver Name</label>
                    <input
                      id="d_name_{{ i }}"
                      type="text"
                      class="form-control mb-1 inputHeight"
                      name="driver_name"
                      [(ngModel)]="row.driver_name"
                      (input)="reqDataValidation()"
                    />
                  </div>
                  <div style="position: relative; margin-left: 20px">
                    <label class="label-head">Company Name</label>
                    <!-- <input
                    id="c_name"
                    type="text"
                    class="form-control mb-1 inputHeight"
                    [(ngModel)]="companyName"
                    (input)="reqDataValidation()"
                  /> -->
                    <select
                      class="form-control mb-1 inputHeight"
                      [(ngModel)]="row.company_name"
                      required
                      (change)="reqDataValidation()"
                      name="company_name"
                    >
                      <option value=""></option>
                      <option value="FASTLINK">FAST LINK</option>
                      <option value="AMGLOBAL">AM GLOBAL</option>
                      <option value="ONTIME">ONTIME</option>
                      <option value="INNOVATION">INNOVATION</option>
                    </select>
                    <!-- <button class="btn btnVender bg-btn-cancel mr-3" (click)="removeRow(i)">Remove</button> -->
                  </div>
                  <div class="buttonposition">
                    <button class="addbtn btn btn-add" (click)="addRow(i)">
                      <mat-icon class="addicon">add_circle_outline</mat-icon>
                    </button>
                    <button
                      class="addbtn btn bg-btn-cancel"
                      (click)="removeRow(i)"
                      [disabled]="rows.length === 1"
                    >
                      <mat-icon class="addicon">delete_sweep</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="!editable && !normalCostAllocation">
                <div *ngFor="let dynamic of dynamicdata; let i = index">
                  <h6 class="f-12 cost_head">Cost Allocation - {{ i + 1 }}</h6>
                  <div class="displayFlex">
                    <div *ngFor="let item of dynamicAllocationFileds">
                      <div style="position: relative">
                        <label class="label-head" style="margin-bottom: -5px"
                          >{{ item.header }}
                        </label>
                        <input
                          type="text"
                          [value]="dynamic[item.field]"
                          [title]="dynamic[item.field]"
                          (input)="
                            updateEditedValue(
                              dynamic.iddynamiccostallocation,
                              item.field,
                              $event.target.value
                            )
                          "
                          class="form-control mb-1 inputHeight"
                          [readonly]="
                            item.field === 'iddynamiccostallocation' ||
                            item.field === 'documentID'
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!reqServiceprovider">
                <div *ngFor="let a of costAllocation; let i = index">
                  <h6 class="f-12 cost_head">Cost Allocation - {{ i + 1 }}</h6>
                  <div class="displayFlex">
                    <div *ngFor="let b of allocationFileds">
                      <div style="position: relative" class="input_container">
                        <label class="label-head" style="margin-bottom: -5px"
                          >{{ b.header }}
                        </label>
                        <input
                          #inputv
                          type="text"
                          [value]="a[b.field]"
                          [title]="a[b.field]"
                          disabled
                          class="form-control inv_inp_cls"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              <ngx-json-viewer [json]="JsonData" [depth]="3"></ngx-json-viewer>
            </div>
          </div>
        </ng-template>

        <!--Dynamic cost allocation-->
        <ng-template #dynamic>
          <div
            class="tab-pane fade header_container"
            [ngClass]="currentTab == 'dynamic' ? 'show active' : ''"
            id="dynamic"
            role="tabpanel"
            aria-labelledby="dynamic-tab"
          >
            <div class="f-12 d-flex justify-content-end" [ngStyle]="{color: subStatusId == 110 ? 'red':''}">
              <span *ngIf="subStatusId == 110"  class="mr-2"
                pTooltip="Please compare the current month VAT in the header data"
                tooltipPosition="top">
                <i class="fa fa-info-circle"></i>
              </span>
              Total Calculated Tax - {{totalTaxDynamic.toFixed(2)}}
            </div>
            <div>

              <table class="dynamic">
                <tr>
                  <th class="f-12 lineName s_count">S.No</th>
                  <th *ngFor="let item of dynamicAllocationFileds" class="f-12 lineName"> {{ item.header }} </th>
                </tr>
                <tr class="invert_tr" *ngFor="let dynamic of dynamicdata; let i = index">
                  <!-- <div style="position: relative" class="input_container" [ngStyle]="{border: (subStatusId == 110 && item.header == 'Tax amount') ? '1px solid red':''}"> -->
                    <td class="s_count f-12 linevalue invert_th_td">{{ i + 1 }}</td>
                    <td class="linevalue invert_th_td"  *ngFor="let item of dynamicAllocationFileds">
                      <input
                        type="text"
                        [value]="dynamic[item.field]"
                        [title]="dynamic[item.field]"
                        (input)="
                          updateEditedValue(
                            dynamic.iddynamiccostallocation,
                            item.field,
                            $event.target.value
                          )
                        "
                        class="form-control inv_inp_cls"
                        [readonly]="
                          item.field === 'iddynamiccostallocation' ||
                          item.field === 'documentID' || !editable
                        "
                        [ngStyle]="{border: (subStatusId == 110 && item.header == 'Tax amount') ? '1px solid red':''}"
                      />
                    </td>
                  <!-- </div> -->
                </tr>
              </table>
            </div>
          </div>
        </ng-template>

        <!--PO lines-->
        <ng-template #poline>
          <div
            *ngIf="currentTab == 'poline'"
            class="tab-pane fade"
            [ngClass]="currentTab == 'poline' ? 'show active' : ''"
            id="POline"
            role="tabpanel"
            aria-labelledby="POline-tab"
          >
            <div style="margin-top: 35px">
              <po-lines [po_num]="po_num"></po-lines>
            </div>
          </div>
        </ng-template>

        <ng-template #grn>
          <div
            class="tab-pane fade"
            [ngClass]="currentTab == 'grn' ? 'show active' : ''"
            id="grn"
            role="tabpanel"
            aria-labelledby="grn-tab"
          >
            <mapped-grn [GRNTabData]="GRNTabData" [grnTabDatalength]="grnTabDatalength"></mapped-grn>
          </div>
        </ng-template>

        <!-- Support Doc -->
        <ng-template #support>
          <div
            class="tab-pane fade"
            [ngClass]="currentTab == 'support' ? 'show active' : ''"
            id="support"
            role="tabpanel"
            aria-labelledby="support-tab"
            style="position: relative"
          >
            <div
              *ngIf="
                supportTabBoolean &&
                support_doc_list?.length > 0 || grnAttachmentArray &&
                !approval_selection_boolean
              "
            >
              <table class="table" *ngIf="support_doc_list?.length > 0">
                <tr class="f-12 lineName">
                  <th style="width: 100px;">S.No</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                <tr
                  *ngFor="let file of support_doc_list; let i = index"
                  class="f-11"
                >
                  <td>{{ i + 1 }}</td>
                  <td
                    class="break_word pointer"
                    (click)="downloadDoc(file, 'view')"
                  >
                    {{ file }}
                  </td>
                  <td style="width: 100px;">
                    <i
                      class="fa fa-download pointer mr-2"
                      aria-hidden="true"
                      (click)="downloadDoc(file, 'down')"
                    ></i>
                    <i
                      *ngIf="editable"
                      class="fa fa-trash-o pointer"
                      aria-hidden="true"
                      (click)="deleteSupport(file)"
                    ></i>
                  </td>
                </tr>
              </table>
              <h6 class="f-14" *ngIf="grnAttachmentArray"> GRN Attachment</h6>
              <ng-container *ngIf="grnAttachmentArray">
                <ng-container *ngFor="let attachment of grnAttachmentArray">
                  <div class="f-12" *ngIf="attachment && attachment[0]">GRN number: {{ attachment[0]?.grnNumber }}</div>
                  <table class="table" *ngIf="attachment && attachment[0]">
                    <tr class="f-12 lineName">
                      <th style="width: 100px;">S.No</th>
                      <th>Name</th>
                      <th></th>
                    </tr>
                    <tr class="f-12" *ngFor="let grn of attachment; let i=index">
                      <td>{{i+1}}</td>
                      <td
                        class="break_word pointer f-12"
                        (click)="grnAttachmentDoc(grn.base64, 'view')"
                      >
                        GRN attachment file {{i+1}}
                      </td>
                      <td style="width: 100px;">
                        <i
                          class="fa fa-download pointer"
                          aria-hidden="true"
                          (click)="grnAttachmentDoc(grn.base64, 'down')"
                        ></i>
                      </td>
                    </tr>
                  </table>
                </ng-container>
              </ng-container>


              <p class="f-11">
                *SERINA is not reading the supporting document
                <strong>(.pdf only)</strong>
                . Please check and review it manually.
              </p>
            </div>
            <div
              *ngIf="
                supportTabBoolean &&
                !(support_doc_list?.length > 0) && grnAttachmentString == undefined &&
                !approval_selection_boolean
              "
            >
              <div class="no_doc_div f-14">
                <div>
                No Supporting Document available, <span *ngIf="!(editable || fin_boolean)"> please upload it in exception screen if you have any.</span><span *ngIf="editable || fin_boolean"> please upload if you have any.</span> <br>
                </div>
                <div *ngIf="GRN_PO_Bool"> Please note once you create the GRN then only supporting documents will be stored.</div>

              </div>

            </div>
            <div class="file_table" *ngIf="uploadFileList.length > 0 ">
              <table class="table">
                <tr class="f-12">
                  <th>S.No</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                <tr
                  *ngFor="let file of uploadFileList; let i = index"
                  class="f-11"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ file.name }}</td>
                  <td>
                    <i
                      class="fa fa-trash-o"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeUploadQueue(i)"
                    ></i>
                  </td>
                </tr>
              </table>
            </div>
            <div *ngIf="supportTabBoolean && !approval_selection_boolean && (editable || fin_boolean)">
              <label
                for="img"
                class="img-browse"
                [ngClass]="uploadFileList.length > 0 ? 'disable_clas' : ''"
              >
                Attach Document
              </label>
              <input
                (change)="onSelectFile($event)"
                #fileInput
                type="file"
                id="img"
                name="img"
                accept=".pdf,.doc, .docx"
                multiple
                [disabled]="uploadFileList.length > 0"
                hidden
              />
              <!-- <input type="file" multiple (change)="uploadSupport($event)"> -->

              <button
                *ngIf="!GRN_PO_Bool"
                class="img-browse h-35 b-0 ml-2"
                (click)="uploadSupport(invoiceID)"
                [disabled]="!(uploadFileList.length > 0)"
                [ngClass]="!(uploadFileList.length > 0) ? 'disable_clas' : ''"
              >
                Upload
              </button>
            </div>
          </div>
        </ng-template>

        <ng-template #history>
          <div
            class="tab-pane fade reject_comment"
            [ngClass]="currentTab == 'history' ? 'show active' : ''"
            id="support"
            style="position: relative"
          >
            <!-- <table class="reject_comment"> 
                <tr><th>S.No</th><th>Rejected By</th><th>Comments</th></tr>
                <tr *ngFor="let item of approvalRejectRecord;let i = index;">
                  <td>{{i+1}}</td>
                  <td>{{item.firstName}}{{item.lastName}}</td>
                  <td>{{item.DocumentHistoryLogs.documentdescription}}</td>
                </tr>
              </table> -->
            <span *ngFor="let item of approvalRejectRecord; let i = index">
              <span
                [ngStyle]="{
                  visibility: item.DocumentHistoryLogs.CreatedOn
                    ? 'visible'
                    : 'hidden'
                }"
                class="date_box"
              >
                <span *ngIf="item.DocumentHistoryLogs.CreatedOn">{{
                  item.DocumentHistoryLogs?.CreatedOn + "Z" | date : "medium"
                }}</span>
              </span>
              <span class="circles"></span>
              <!-- <span class="material-icons circles" [class.active]="((orderStatus == item.dochistorystatus) && (i == statusData.length-1))">
                            check_circle
                        </span> -->

              <div class="circle-desc">
                <div class="row">
                  <div class="col-sm-6">
                    <div>
                      <span class="f-12"
                        ><span
                          *ngIf="
                            item.DocumentHistoryLogs?.documentSubStatusID == 82
                          "
                          style="color: red"
                          >Rejection </span
                        >Done by</span
                      >
                      :
                      <span style="font-weight: 500"
                        >{{ item.firstName }} {{ item.lastName }}</span
                      >
                    </div>
                    <div *ngIf="item.DocumentHistoryLogs.documentdescription">
                      <span class="f-12">Description</span> :
                      <span style="font-weight: 500">{{
                        item.DocumentHistoryLogs.documentdescription
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <span
                class="bar"
                *ngIf="i != approvalRejectRecord.length - 1"
              ></span>
            </span>
          </div>
        </ng-template>

        <!--Approval Selection-->
        <ng-template #approver_selection>
          <div
            class="tab-pane fade"
            [ngClass]="
              approval_selection_boolean &&
              !isLCMInvoice &&
              currentTab == 'approver_selection'
                ? 'show active'
                : ''
            "
            id="approver_selection"
            role="tabpanel"
            aria-labelledby="approver_selection-tab"
            style="position: relative"
          >
            <div *ngIf="selectionTabBoolean" class="selection_Div">
              <div
                class="d-flex justify-content-between flex-wrap f-12"
                style="gap: 10px"
              >
                <div class="input_box">
                  <label class="mb-0">Select Entity</label>
                  <select
                    class="form-control inputHeight input_box_field"
                    [(ngModel)]="entityName"
                    [title]="entityName"
                    name="entity"
                    disabled
                  >
                    <option
                      *ngFor="let entity of entityList"
                      [value]="entity.EntityName"
                    >
                      {{ entity.EntityName }}
                    </option>
                  </select>
                </div>
                <div class="input_box">
                  <label class="mb-0">Select Department</label>
                  <select
                    class="form-control inputHeight input_box_field"
                    name="department"
                    [(ngModel)]="selectedDepartment"
                    (change)="onSelectDepartment($event.target.value)"
                  >
                    <option
                      *ngFor="let name of DepartmentList"
                      [value]="name.DepartmentName"
                    >
                      {{ name.DepartmentName }}
                    </option>
                  </select>
                </div>
                <!-- <div>
                    <label>Select Category</label>
                    <select
                      class="form-control inputHeight"
                      (change)="onSelectCategory($event.target.value)"
                    >
                      <option value=""></option>
                    </select>
                  </div> -->
                <div
                  class="input_box"
                  *ngFor="let item of approverList | keyvalue; let i = index"
                >
                  <label class="mb-0">Select Approver {{ i + 1 }}</label>
                  <!-- <div>{{item.value | json}}</div> -->
                  <select
                    class="form-control inputHeight input_box_field"
                    name="approvers"
                    [disabled]="preApproveBoolean"
                    (change)="onSelectApprovers($event.target.value, i)"
                  >
                    <option
                      *ngFor="let element of item.value"
                      [value]="element.User.idUser"
                    >
                      {{ element.User.firstName }} {{ element.User.lastName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mt-2">
                <label for="pre_approve" class="f-12">
                  <input
                    type="checkbox"
                    id="pre_approve"
                    class="mr-2"
                    name="preApprove"
                    [(ngModel)]="preApproveBoolean"
                    (change)="onSelectPreApprove(preApproveBoolean)"
                  />
                  <span class="label_adjust">Pre Approved</span></label
                >
              </div>

              <div>
                <label
                  for="img"
                  class="img-browse bg-blue"
                  [ngClass]="uploadFileList.length > 0 ? 'disable_clas' : ''"
                >
                  Attach support Document
                </label>
                <input
                  (change)="onSelectFileApprove($event)"
                  #fileInput
                  type="file"
                  id="img"
                  name="img"
                  multiple
                  [disabled]="uploadFileList.length > 0"
                  hidden
                />
                <!-- <input type="file" multiple (change)="uploadSupport($event)"> -->

                <!-- <button
                    class="img-browse h-35 b-0 ml-2 bg-blue"
                    (click)="uploadSupport()"
                    [disabled]="!(uploadFileList.length > 0)"
                    [ngClass]="!(uploadFileList.length > 0) ? 'disable_clas' : ''"
                  >
                    Upload
                  </button> -->
                <div class="f-11" style="color: #e56767">
                  Note: Please upload supporting documents if you have any
                  <br />
                  *SERINA is not reading the supporting document<strong> (.pdf only)</strong>. Please check and review it manually.
                </div>
              </div>
            </div>
            <div
              *ngIf="
                currentTab == 'approver_selection' &&
                support_doc_list?.length > 0
              "
              class="selection_Div"
              style="min-height: auto"
            >
              <h6 class="f-13">Supporting Documents</h6>

              <table class="table">
                <tr class="f-12 lineName">
                  <th>S.No</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                <tr
                  *ngFor="let file of support_doc_list; let i = index"
                  class="f-11"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ file }}</td>
                  <td>
                    <i
                      class="fa fa-download"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="downloadDoc(file, '')"
                    ></i>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </ng-template>

        <div class="btnFooter">
          <div class="m_r_80" *ngIf="!grnCreateBoolean">
            <button
              *ngIf="submitBtn_boolean && editable &&  !isMoreRequired "
              class="level_3_btn f-12 mr-3"
              (click)="open_dialog('reject')"
            >
              Reject
            </button>
            <button
              class="secondary_btn f-12 mr-3"
              type="button"
              (click)="backToInvoice()"
            >
              Back
            </button>

            <button
            *ngIf="submitBtn_boolean 
            && editable 
            && !fin_boolean 
            && isServiceData 
            && ![29,32,80]?.includes(subStatusId)
            && !router.url.includes('uploadtime')"
            class="primary_btn f-12 mr-3"
            (click)="
              approval_selection_boolean && isLCMCompleted
                ? onSubmitApprovers()
                : proceedToBatch('true')
            "
          >
            Skip
          </button>

            <button
              *ngIf="submitBtn_boolean && editable && !fin_boolean"
              class="primary_btn f-12"
              (click)="
                approval_selection_boolean && isLCMCompleted
                  ? onSubmitApprovers()
                  : proceedToBatch('false')
              "
            >
              {{subStatusId == 206 ? 'Approve' : 'Next'}}
            </button>
            <button
              *ngIf="fin_boolean && client_name == 'Enova' && !isMoreRequired && !isServiceData"
              type="button"
              class="primary_btn f-12 mr-3"
              (click)="open_dialog('more')"
            >
              More info required
            </button>
            <button
              *ngIf="fin_boolean && !isMoreRequired"
              class="primary_btn f-12"
              (click)="batch_id ? bulk_confirm() :open_dialog('approve')"
            >
              Approve
            </button>
            <button
              *ngIf="fin_boolean &&  client_name == 'Enova' && isMoreRequired"
              type="button"
              class="primary_btn f-12 mr-3"
              (click)="open_dialog('more')"
            >
              Proceed
            </button>
          </div>

          <div *ngIf="grnCreateBoolean && isGRNDataLoaded" class="m_r_80">
            <button
              *ngIf="!GRN_PO_Bool"
              class="level_3_btn f-12 mr-3"
              type="button"
              (click)="open_dialog('reject')"
            >
              Reject
            </button>
            <button
              class="secondary_btn f-12 mr-3"
              type="button"
              (click)="backToInvoice()"
            >
              Back
            </button>
            <button
              *ngIf="client_name == 'Cenomi'"
              class="primary_btn f-12 mr-3"
              type="submit"
              [disabled]="!enable_create_grn || disable_save_btn "
              [pTooltip]="disable_save_btn ? grnTooltip : null"
              tooltipPosition="top"
              (click)="
                router.url.includes('GRN_approvals')
                  ? Approve_grn()
                  : confirm_pop(form.value, true, 'GRN saved successfully')
              "
            >
              Save
            </button>
            <button
              class="primary_btn f-12 mr-3"
              type="submit"
              [pTooltip]="disable_save_btn ? grnTooltip : saveDisabled ? 'Please fill all the dates data in the timesheet' : null"
              tooltipPosition="top"
              [disabled]="!enable_create_grn || disable_save_btn || saveDisabled"
              (click)="
                router.url.includes('GRN_approvals')
                  ? Approve_grn()
                  : confirm_pop(form.value, true, 'GRN created successfully')
              "
            >
              {{
                router.url.includes("GRN_approvals") ? "Approve" : "Create GRN"
              }}
            </button>
          </div>
        </div>
      </form>
      <ngx-spinner
        bdColor="rgba(251, 251, 251, 0.8)"
        size="medium"
        color="#070900"
        [fullScreen]="true"
        type="ball-spin-clockwise"
      >
        <p style="color: rgb(0, 0, 0)"></p>
      </ngx-spinner>
    </div>
  </div>
  <div
    *ngIf="showPdf && !['PO','GRN'].includes(Itype)"
    class="col-lg-7 col-12 maxPage ShowInvoiceTypeSize doc_mobile"
    [ngStyle]="{
      display: documentViewBool || (isDesktop && showPdf) ? 'block' : 'none'
    }"
  >
    <section *ngIf="isDesktop">
      <app-pdfview
        [vendorName]="vendorName"
        [invoiceNumber]="invoiceNumber"
        [btnText]="btnText"
        (boolEmit)="getPdfBool($event)"
        (PdfBtnClick)="viewPdf()"
      ></app-pdfview>
    </section>
    <div *ngIf="!isDesktop" class="doc_cls_btn" (click)="doc_view()">
      <i class="fa fa-close"></i>
    </div>
    <div *ngIf="!isDesktop">
      <app-pdfview
        [vendorName]="vendorName"
        [invoiceNumber]="invoiceNumber"
        [btnText]="btnText"
        (boolEmit)="getPdfBool($event)"
        (PdfBtnClick)="viewPdf()"
      ></app-pdfview>
    </div>
    <!-- <img [src]="imageUrl" style="width: 100%;height: 80vh;"><br/> -->
  </div>
</div>

<!-- Rule selection modal-->
<!-- <p-dialog
  header="Change Rule For Batch "
  [(visible)]="displayRuleDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '31vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div>
    <mat-radio-group
      class="example-radio-group"
      [(ngModel)]="SelectRuleOption.value"
    >
      <mat-radio-button
        class="example-radio-button f-13"
        *ngFor="let rule of givenRules"
        [value]="rule"
        [disabled]="rule.IsActive == 0"
      >
        {{ rule.Name }}
        <span *ngIf="rule.IsActive == 0" class="f-11 ml-1 glow"
          >Coming soon</span
        >
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btnVender bg-btn-cancel"
        (click)="displayRuleDialog = false"
      >
        Cancel
      </button>
      <button
        *ngIf="!save_rule_boolean"
        type="button"
        class="btn btnVender bg-btn-success"
        (click)="sendReview()"
      >
        Send for Review Batch
      </button>
      <button
        *ngIf="save_rule_boolean"
        type="button"
        class="btn btnVender bg-btn-success"
        (click)="save_rule()"
      >
        Save
      </button>
    </div>
  </ng-template>
</p-dialog> -->

<!-- Add label-->
<p-dialog
  [header]="headerpop"
  [(visible)]="progressDailogBool"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: p_width, maxHeight: '80svh' }"
  [baseZIndex]="10000"
  [draggable]="true"
  [resizable]="false"
  [closeIcon]="false"
>
  <div *ngIf="headerpop == 'Batch Progress'">
    <div class="f-12" *ngIf="!(batchData?.length > 0)">
      Hey, Please hold on the batch is running...
    </div>
    <ul class="f-12" *ngIf="batchData?.length > 0">
      <li *ngFor="let st of batchData">
        <span [ngStyle]="{ color: st.status == 1 ? 'green' : 'red' }">{{
          st.msg
        }}</span>
      </li>
    </ul>

    <div *ngIf="batch_count > 2" class="f-12 mt-2" style="color: red">
      Oops, looks like something is wrong with the invoice, Please check with
      the Serina support team.
    </div>
  </div>
  <div *ngIf="headerpop == 'Please select the status'">
    <mat-radio-group class="example-radio-group" [(ngModel)]="status_change_op">
      <mat-radio-button
        class="example-radio-button f-13 d-block"
        *ngFor="let st of status_arr"
        [value]="st.st_obj"
      >
        {{ st.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div
    class="row"
    *ngIf="headerpop == 'Confirm PO number' || headerpop == 'Select GRN'"
    style="min-height: 40vh"
  >
    <div class="col-md-4">
      <input
        type="text"
        class="form-control f-12"
        (keyup)="POsearch($event.target.value)"
        placeholder="Search.."
      />
      <ul class="ulPOlist f-12 mt-1" *ngIf="headerpop == 'Confirm PO number'">
        <li
          *ngFor="let poitem of poNumbersList; let i = index"
          [id]="i"
          (click)="selectedPO(i, $event.target.value)"
          [ngClass]="
            activePOId == poitem.Document.PODocumentID ? 'activePO' : ''
          "
        >
          {{ poitem.Document.PODocumentID }}
        </li>
      </ul>
      <div
        class="f-12"
        *ngIf="!(poNumbersList?.length > 0) && headerpop == 'Confirm PO number'"
      >
        Sorry! NO records found.
      </div>

      <ul class="ulPOlist f-12 mt-1" *ngIf="headerpop == 'Select GRN'">
        <li *ngFor="let grn_item of grnList; let i = index" [id]="i">
          <input
            type="checkbox"
            [id]="grn_item.GRNNumber"
            (change)="selectedGRN($event, grn_item.GRNNumber)"
            class="mr-2"
          />
          <label [for]="grn_item.GRNNumber"
            >{{ grn_item.GRNNumber }}
            <span style="color: #358cd0" *ngIf="grn_item?.InvoiceNumber"
              >| Inv -
            </span>
            {{ grn_item?.InvoiceNumber }}</label
          >
        </li>
      </ul>
      <div
        class="f-12"
        *ngIf="!(grnList?.length > 0) && headerpop == 'Select GRN'"
      >
        Sorry! NO records found.
      </div>
    </div>
    <div class="col-md-8">
      <div class="d-flex selection" *ngIf="headerpop == 'Confirm PO number'">
        <div class="w-100">
          <label class="f-12 z_index">Please select the date range</label><br />
          <p-calendar
            #datePicker
            [(ngModel)]="rangeDates"
            selectionMode="range"
            [minDate]="minDate"
            [maxDate]="maxDate"
            placeholder=""
            [readonlyInput]="true"
            [showIcon]="true"
            showButtonBar="true"
            (onClearClick)="clearDates($event)"
            (onSelect)="filterByDate(rangeDates, $event)"
            inputId="range"
          ></p-calendar>
        </div>

        <!-- <p-calendar
          [(ngModel)]="selectDate"
          view="month"
          dateFormat="mm/yy"
          [showIcon]="true"
          [yearNavigator]="true"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [yearRange]="displayYear"
          placeholder="Select dates to filter.."
          [readonlyInput]="false"
          (onSelect)="applyDatefilter(selectDate)"
          inputId="monthpicker"
        ></p-calendar> -->
      </div>
      <h6
        class="f-14 mt-1 d-flex justify-content-between"
        *ngIf="PO_GRN_Number_line?.length > 0"
      >
        <div>Line details</div>
        <div
          class="f-13 po_date"
          *ngIf="headerpop == 'Confirm PO number' && poDate"
        >
          PO Date: {{ poDate[0]?.DocumentData?.Value | date : "medium" }}
        </div>
      </h6>
      <div class="maxHDiv">
        <table
        class="table f-12"
        *ngIf="
          PO_GRN_Number_line?.length > 0 && headerpop == 'Confirm PO number'
        "
      >
        <tr>
          <th style="width: 60px">S.No</th>
          <th>Description</th>
          <th>Quantity</th>
          <th>UnitPrice</th>
          <th>Net Total</th>
        </tr>
        <tr *ngFor="let item of PO_GRN_Number_line; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ item.Name }}</td>
          <td>{{ item.PurchQty }}</td>
          <td>{{ item.UnitPrice }}</td>
          <td>{{ (item.PurchQty * item.UnitPrice).toFixed(2) }}</td>
        </tr>
      </table>
      </div>
      <ng-container *ngIf="headerpop == 'Select GRN'">
        <div *ngFor="let grnLine of selectedGRNLines">
          <div class="f-13">GRN : {{ grnLine?.grnNumber }}</div>
          <div class="maxHDiv">
            <table class="table f-12" style="border: 1px solid lightgray;" *ngIf="grnLine?.linesData?.length > 0">
              <tr>
                <th style="width: 60px">S.No</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>UnitPrice</th>
                <!-- <th>AmountExcTax</th> -->
              </tr>
              <tr *ngFor="let item of grnLine?.linesData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item.Description }}</td>
                <td>{{ item.Quantity }}</td>
                <td>{{ item.UnitPrice }}</td>
                <!-- <td>{{ item.AmountExcTax }}</td> -->
              </tr>
            </table>
          </div>
        </div>
        <div
          *ngIf="selected_GRN_total"
          class="d-flex justify-content-between total_grn f-12"
        >
          <div>Total</div>
          <div>{{ selected_GRN_total }}</div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- <div
    *ngIf="headerpop == 'Select GRN'"
    class="text-center"
    style="min-height: 150px"
  >
    <p-multiSelect
      [options]="grnList"
      placeholder="GRN Number"
      filter="false"
      inputStyleClass="form-control mr-2"
      optionLabel="docheaderID"
      [maxSelectedLabels]="3"
      [selectedItemsLabel]="'{0} items selected'"
      name="PO_GRN_Number"
      [(ngModel)]="selectedGRNList"
      required
    >
    </p-multiSelect>
  </div> -->

  <div
    *ngIf="headerpop == 'GRN Creation Status'"
    class="f-13"
    style="color: #f38a6b"
  >
    {{ APIResponse }}
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end">
      <button
        *ngIf="headerpop == 'Batch Progress' && (!isAprUser || subStatusId != 71)"
        type="button"
        class="primary_btn f-12 mt-2"
        (click)="
          isBatchFailed && batch_count <= 2 ? syncBatch() : routeToMapping()
        "
      >
        <span>{{ isBatchFailed && batch_count <= 2 ? "Re-try" : "Next" }}</span>
      </button>
      <!-- <button
        *ngIf="headerpop == 'Batch Progress' && isAprUser && subStatusId == 71"
        type="button"
        class="secondary_btn f-12 mt-2 mr-2"
        (click)="open_dialog('reject')"
      >
        Reject
      </button> -->
      <button
        *ngIf="headerpop == 'Batch Progress' && isAprUser && subStatusId == 71 && !isServiceData"
        type="button"
        class="primary_btn f-12 mt-2 mr-2"
        (click)="open_dialog('more')"
      >
        More info required
      </button>
      <button
        *ngIf="headerpop == 'Batch Progress' && isAprUser && subStatusId == 71"
        type="button"
        class="primary_btn f-12 mt-2"
        (click)="open_dialog('approve')"
      >
        Approve & Proceed
      </button>
      <button
        *ngIf="headerpop != 'Batch Progress'"
        type="button"
        class="secondary_btn f-12 mt-2 mr-2"
        (click)="progressDailogBool = false"
      >
        Close
      </button>
      <button
        *ngIf="headerpop == 'Select GRN'"
        type="button"
        class="primary_btn f-12 mt-2"
        [disabled]="!(selectedGRNList?.length > 0)"
        (click)="ChangeGRNData()"
      >
        Change
      </button>
      <button
        *ngIf="headerpop == 'GRN Creation Status'"
        type="button"
        class="secondary_btn f-12 mt-2 mr-2"
        (click)="backToInvoice()"
      >
        Go Back
      </button>
      <button
        *ngIf="headerpop == 'Confirm PO number'"
        type="button"
        class="primary_btn f-12 mt-2"
        [disabled]="!activePOId"
        (click)="confirmPO()"
      >
        Confirm
      </button>
      <button
        *ngIf="headerpop == 'Please select the status'"
        type="button"
        class="primary_btn f-12 mt-2"
        [disabled]="!status_change_op"
        (click)="status_change()"
      >
        Confirm
      </button>
    </div>
  </ng-template>
</p-dialog>

<!-- edit type selection modal-->
<!-- <p-dialog
  header="Select Error type "
  [(visible)]="displayErrorDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '31vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div>
    <mat-radio-group
      class="example-radio-group"
      [(ngModel)]="SelectErrorOption"
    >
      <mat-radio-button
        class="example-radio-button f-13"
        *ngFor="let rule of givenErrors"
        [value]="rule.idbatcherrortypes"
      >
        {{ rule.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btnVender bg-btn-cancel"
        (click)="cancelSelectErrorRule()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btnVender bg-btn-success"
        (click)="updateLine()"
      >
        Save Changes
      </button>
    </div>
  </ng-template>
</p-dialog> -->

<!-- Reject modal-->
<p-dialog
  [header]="rejectModalHeader"
  [(visible)]="displayrejectDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '450px' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div>
    <div
      class="input_box mb-2"
      *ngIf="rejectModalHeader == 'ADD Rejection Comments' && fin_boolean"
    >
      <label class="f-12 mb-0">Please select the user to reject</label>
      <select
        name="reject_user"
        id="reason_user"
        class="form-control f-14 p-2 input_box_field"
        (change)="selectUserForReject($event.target.value)"
      >
        <option value="" disabled>Select User</option>
        <option *ngFor="let x of userList_approved" [value]="x.idUser">
          {{ x.firstName }} {{ x.lastName }}
        </option>
      </select>
    </div>
    <div
      class="input_box"
      *ngIf="rejectModalHeader == 'ADD Rejection Comments'"
    >
      <div class="d-flex justify-content-between">
        <label class="f-12 mb-0">Please select the reason for rejection</label>
        <i
          *ngIf="this.rejectionComments != ''"
          class="fa fa-edit ml-1 z_index"
          style="cursor: pointer"
          (click)="open_dialog_comp('Amend')"
        ></i>
      </div>
      <select
        *ngIf="!addrejectcmtBool"
        name="reject"
        id="reason"
        class="form-control f-14 p-2 input_box_field"
        (change)="selectReason(rejectionComments)"
        [(ngModel)]="rejectionComments"
      >
        <option value="" disabled>Select reason</option>
        <option *ngFor="let x of rejectReason" [value]="x.reason">
          {{ x.reason }}
        </option>
      </select>
      <input
        *ngIf="addrejectcmtBool"
        type="text "
        class="form-control input_box_field f-14"
        (keyup)="rejectKepup($event.target.value)"
        placeholder="Please enter the reason"
      />
    </div>

    <div *ngIf="rejectModalHeader == 'Check Item Code Availability'">
      <div class="input_box">
        <label class="f-12 mb-0"
          >Please enter Item code number and Check is it available or
          not.</label
        >
        <input
          #ItemCode
          type="number"
          class="form-control f-14 input_box_field"
        />
      </div>
      <div class="d-flex justify-content-end mt-2">
        <button
          class="primary_btn f-12"
          (click)="CheckItemStatus(ItemCode.value)"
        >
          Check & ADD
        </button>
      </div>
    </div>

    <div
      *ngIf="
        rejectModalHeader == 'Add Pre-approval Comments' ||
        rejectModalHeader == 'Please Add Comments'
      "
    >
      <label class="label-head mt-2 mb-0">Comments</label> <br />
      <!-- <small *ngIf="preApproveBoolean" class="f-11 cmt_text"
      >Desc: Add the Names of the Signatory is mandatory</small
    > -->

      <textarea
        required
        name="comment"
        class="form-control"
        style="font-size: 13px"
        minlength="0"
        (keyup)="addComments($event.target.value)"
        cols="50"
        rows="2"
      ></textarea>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div
      class="d-flex justify-content-end"
      *ngIf="rejectModalHeader == 'ADD Rejection Comments'"
    >
      <!-- <button
        type="button"
        class="btn btnVender bg-btn-cancel"
        (click)="displayrejectDialog = false"
      >
        Cancel
      </button> -->
      <button
        type="button"
        class="f-12 primary_btn"
        [disabled]="rejectionComments == '' || rejectionComments == 'Others'"
        [ngStyle]="{
          cursor:
            rejectionComments == '' || rejectionComments == 'Others'
              ? 'not-allowed'
              : 'pointer'
        }"
        (click)="Reject()"
      >
        Send for Rejection
      </button>
    </div>
    <div
      class="d-flex justify-content-end"
      *ngIf="rejectModalHeader != 'ADD Rejection Comments'"
    >
      <button
        *ngIf="preApproveBoolean"
        type="button"
        class="primary_btn f-12"
        (click)="displayrejectDialog = false"
      >
        Ok
      </button>

      <button
        *ngIf="isApproveCommentBoolean"
        type="button"
        class="primary_btn f-12"
        [disabled]="commentsBool"
        [ngStyle]="{ cursor: commentsBool ? 'not-allowed' : 'pointer' }"
        (click)="financeApprove()"
      >
        Approve
      </button>
      <button
        *ngIf="moreInfoBool"
        type="button"
        class="primary_btn f-12"
        [disabled]="commentsBool"
        [ngStyle]="{ cursor: commentsBool ? 'not-allowed' : 'pointer' }"
        (click)="isMoreRequired? proceedMoreInfo() : moreInfoFun() "
      >
        {{ !isMoreRequired? 'More info required':'Proceed' }}
      </button>
      <button
        *ngIf="isLCMSubmitBoolean && !preApproveBoolean"
        type="button"
        class="primary_btn f-12"
        (click)="submitLCMLines()"
      >
        Confirm
      </button>
    </div>
  </ng-template>
</p-dialog>

<dialog>
  
  <div *ngIf="!this.router.url.includes('Create_GRN_inv_list')">
    <div class="d-flex justify-content-between mb-2">
      <h6 class="f-14">Approval comments</h6>
      <div class="f-14 pointer" (click)="closeDialog()"> <i class="fa fa-close"></i></div>
    </div>
    <div>
      <div>
        <label class="label-head mt-2 mb-0">Comments</label> <br />
        <!-- <small *ngIf="preApproveBoolean" class="f-11 cmt_text"
        >Desc: Add the Names of the Signatory is mandatory</small
      > -->
    
        <textarea
          required
          name="comment"
          class="form-control"
          style="font-size: 13px"
          minlength="0"
          (keyup)="addComments($event.target.value)"
          cols="50"
          rows="2"
        ></textarea>
      </div>
      <div class="f-12" id="cmt">
        Note: Please add comment for the bulk approval for the same batch invoices.
      </div>
    </div>
  
    <div class="d-flex justify-content-end mt-3">
      <button
        type="button"
        class="primary_btn f-12"
        [disabled]="commentsBool"
        [ngStyle]="{ cursor: commentsBool ? 'not-allowed' : 'pointer' }"
        (click)="financeApprove()"
      >
        Approve
      </button>
    </div>
  </div>
</dialog>
<p-toast></p-toast>
